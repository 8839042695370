import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import SearchModal from "../search-modal"
import TypeIconSvg from "../type-icon-svg"
import languages from "../../config/languages"
import { ReactComponent as ChevronDownSvg } from "../../assets/images/icons/chevron-down.svg"
import { ReactComponent as SvgMagnifyingGlass } from "../../assets/images/icons/magnifying-glass.svg"

const ENABLE_SEARCH = !!process.env.GATSBY_TYPESENSE_KEY

const ButtonLink = ({ to, ...props }) =>
  to ? <Link to={to} {...props} /> : <button type="button" {...props} />

const SubItems = ({ data }) => {
  return (
    <SubItemsContainer>
      {data.map((item, i) => (
        <li key={i}>
          <Link to={item.url}>
            {item.name && <TypeIconSvg name={item.name} />}

            <span dangerouslySetInnerHTML={{ __html: item.title }} />
          </Link>
        </li>
      ))}
    </SubItemsContainer>
  )
}

const PrimaryNav = () => {
  const { config, translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        search {
          title
          slug
        }
      }

      config: configJson {
        types {
          name
          slug
          color
          title
        }

        header {
          primaryNav: primary_nav {
            types
            items {
              title
              url
              sub {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const [searchModalOpen, setSearchModalOpen] = useState(false)

  const langCurrent = languages.find((i) => i.code === process.env.GATSBY_LANG)
  const langSub = []

  languages.forEach(
    (lang) => lang.code !== langCurrent.code && langSub.push(lang)
  )

  const langItems = [{ title: langCurrent.title, sub: langSub }]

  const items = [
    {
      title: config.header.primaryNav.types,
      sub: config.types.map((t) => ({ url: `/${t.slug}`, ...t })),
    },
    ...config.header.primaryNav.items,
  ]

  const searchModalRequestClose = () => {
    setSearchModalOpen(false)
  }

  const searchButtonClick = (e) => {
    e.preventDefault()
    setSearchModalOpen(true)
  }

  return (
    <Container>
      <Base>
        {items.map((base, i) => (
          <li key={i}>
            <ButtonLink to={base.url}>
              <span dangerouslySetInnerHTML={{ __html: base.title }} />

              {base.sub && <ChevronDownSvg />}
            </ButtonLink>

            {base.sub && (
              <Sub>
                <SubItems data={base.sub} />
              </Sub>
            )}
          </li>
        ))}

        {ENABLE_SEARCH && (
          <li>
            <ButtonLink
              to={`/${translations.search.slug}`}
              onClick={searchButtonClick}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: translations.search.title,
                }}
              />

              <SvgMagnifyingGlass aria-hidden="true" className="--search" />
            </ButtonLink>
          </li>
        )}

        {langItems.map((base, i) => (
          <li key={i}>
            <ButtonLink to={base.url}>
              <span dangerouslySetInnerHTML={{ __html: base.title }} />

              {base.sub && base.sub.length > 0 && <ChevronDownSvg />}
            </ButtonLink>

            {base.sub && base.sub.length > 0 && (
              <Sub>
                <SubItems data={base.sub} />
              </Sub>
            )}

            <SearchModal
              isOpen={searchModalOpen}
              onRequestClose={searchModalRequestClose}
            />
          </li>
        ))}
      </Base>
    </Container>
  )
}

export default PrimaryNav

const Container = styled.nav`
  a,
  button {
    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }

    &:active {
      opacity: 0.6;
    }
  }
`

const Sub = styled.div`
  width: ${rem(280)};
  padding: 1.5em;
  display: none;
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 ${rem(4)} ${rem(10)} rgba(0, 0, 0, 0.2);
  border-radius: 0.5em;
  animation: 0.2s ${({ theme }) => theme.easings.default};
  animation-name: ${({ theme }) => theme.animations.slideInY(-10)},
    ${({ theme }) => theme.animations.fadeIn};

  @media ${({ theme }) => theme.mq.largeDown} {
    width: 100%;
    position: static;
    margin-top: 0.5em;
    padding: 0.75em;
    box-shadow: 0 ${rem(2)} ${rem(6)} rgba(0, 0, 0, 0.2);
  }
`

const Base = styled.ul`
  display: flex;

  @media ${({ theme }) => theme.mq.largeDown} {
    display: block;
  }

  > li {
    position: relative;

    &:hover,
    &:focus,
    &:focus-within {
      > a,
      > button {
        color: ${({ theme }) => theme.colors.blue};
      }

      ${Sub} {
        display: block;
      }
    }

    > a,
    > button {
      ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

      padding: 0.5em 1em;
      display: block;
      font-size: ${rem(18)};

      @media ${({ theme }) => theme.mq.largeDown} {
        padding: 0.2em 0;
      }

      svg {
        width: 0.7em;
        height: 0.7em;
        margin-left: 0.4em;
        display: inline-block;
      }
    }
  }
`

const SubItemsContainer = styled.ul`
  li {
    &:not(:last-child) {
      margin-bottom: 1em;
    }

    > .-icon {
      flex-shrink: 0;

      svg {
        padding: 5%;
      }
    }
  }

  a {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    font-size: ${rem(18)};
    display: flex;
    align-items: center;
  }

  svg {
    width: 1.8em;
    height: 1.8em;
    margin-right: 0.6em;
    color: ${({ theme }) => theme.colors.blue};
  }
`
