import { rem } from "polished"

const searchResult = {
  br: rem(10),
  backgroundColor: ({ theme }) => theme.colors.cream,
  labelColor: ({ theme }) => theme.colors.white,
  labelBackgroundColor: ({ theme }) => theme.colors.gold,
  labelBr: rem(5),
}

export default searchResult
