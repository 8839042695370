import React from "react"
import styled from "styled-components"
// import { rem } from "polished"

// import Filters from "./filters"
import Grid from "./grid"
// import Pagination from "../pagination"

const Container = styled.section``

// const PaginationWrap = styled.div`
//   margin-top: ${rem(30)};
// `

const SitesGrid = ({ data, type, ...props }) => {
  if (!data || !data.length) return null

  return (
    <Container {...props}>
      {/* <Filters data={data} /> */}

      <Grid data={data} type={type} />

      {/* <PaginationWrap>
        <Pagination pageCount={20} onPageChange={() => null} />
      </PaginationWrap> */}
    </Container>
  )
}

export default SitesGrid
