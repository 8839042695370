import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { ParagraphLarge } from "../styled/paragraph"
import { ReactComponent as SvgInstagram } from "../../assets/images/icons/instagram.svg"
// import { ReactComponent as SvgFacebook } from "../../assets/images/icons/facebook.svg"
// import { ReactComponent as SvgTwitter } from "../../assets/images/icons/twitter.svg"
// import { ReactComponent as SvgYoutube } from "../../assets/images/icons/youtube.svg"
// import { ReactComponent as SvgLinkedin } from "../../assets/images/icons/linkedin.svg"
import { ReactComponent as SvgLogo } from "../../assets/images/logo-transparent.svg"

const Container = styled.section`
  position: relative;
  top: ${rem(-30)};

  @media ${({ theme }) => theme.mq.xlargeDown} {
    top: unset;
  }
`

const Logos = styled.h2`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: ${rem(250)};

  @media ${({ theme }) => theme.mq.xlargeDown} {
    justify-content: center;
  }

  @media ${({ theme }) => theme.mq.xlargeDown} {
    margin: 0 auto;
  }

  a {
    transition: opacity 0.2s ${({ theme }) => theme.easings.default};
    overflow: hidden;
    height: ${rem(80)};
    margin-bottom: ${rem(20)};

    &:hover {
      opacity: 0.8;
    }

    &:active {
      transition: none;
      opacity: 0.5;
    }
  }

  img {
    width: 7em;
    height: 3em;
    display: block;
  }

  svg {
    width: ${rem(250)};
    height: ${rem(175)};
    display: block;
    position: relative;
    right: ${rem(30)};
    bottom: ${rem(55)};

    @media ${({ theme }) => theme.mq.xlargeDown} {
      right: 0;
    }
  }
`

const Description = styled(ParagraphLarge)`
  color: inherit;
`

const Social = styled.ul`
  margin-top: 2em;

  @media ${({ theme }) => theme.mq.xlargeDown} {
    margin-top: 1.5em;
  }

  li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 1em;
    }
  }

  a {
    width: 1.875em;
    height: 1.875em;
    padding: 0.3em;
    display: block;
    transition: opacity 0.2s ${({ theme }) => theme.easings.default};

    &:hover {
      opacity: 0.8;
    }

    &:active {
      transition: none;
      opacity: 0.5;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const About = ({ ...props }) => {
  const { config } = useStaticQuery(graphql`
    query {
      config: configJson {
        footer {
          about
        }
      }
    }
  `)

  return (
    <Container {...props}>
      <Logos>
        <Link to="/" title="Trails of Västmanland">
          <SvgLogo aria-label="Trails of Västmanland" />
        </Link>
      </Logos>

      <Description dangerouslySetInnerHTML={{ __html: config.footer.about }} />

      <Social>
        <li>
          <Link
            to="https://www.instagram.com/trailsofvastmanland"
            target="_blank"
            title="Instagram"
            aria-label="Instagram"
          >
            <SvgInstagram />
          </Link>
        </li>

        {/* <li>
          <Link to="/" target="_blank" title="Facebook" aria-label="Facebook">
            <SvgFacebook />
          </Link>
        </li>

        <li>
          <Link to="/" target="_blank" title="Twitter" aria-label="Twitter">
            <SvgTwitter />
          </Link>
        </li>

        <li>
          <Link to="/" target="_blank" title="Youtube" aria-label="Youtube">
            <SvgYoutube />
          </Link>
        </li>

        <li>
          <Link to="/" target="_blank" title="Linkedin" aria-label="Linkedin">
            <SvgLinkedin />
          </Link>
        </li> */}
      </Social>
    </Container>
  )
}

export default About
