const typesCategories = {
  // biking: [`31`], // biking
  mountainbiking: [`16`, `31`], // mountainbiking, biking
  hiking: [`33`], // hiking
  paddling: [`37`], // canoeing
  outdoors: [`30`], // nature
  // hub: [], // everything else
}

const typesCategoriesIncluded = typesCategories
const typesCategoriesPreselected = typesCategories

module.exports = {
  typesCategories,
  typesCategoriesIncluded,
  typesCategoriesPreselected,
}
