import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import EventCard from "../components/event-card"
import EventCardTiny from "../components/event-card-tiny"
import Icon from "../components/icon"
import { Label2, Label3 } from "../components/styled/label"
import { ReactComponent as SvgCalendar } from "../assets/images/icons/calendar.svg"

const Hero = styled.div`
  padding: ${rem(40)} 0;
  background-color: ${({ theme }) => theme.colors.creamLight};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${rem(20)};
    padding-bottom: ${rem(20)};
  }

  > div {
    ${({ theme }) => theme.grid.container()}
  }

  h1 {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.metallic};

    .-icon {
      width: 3em;
      height: 3em;
      margin-right: 0.75em;
    }
  }
`

const Highlights = styled.ul`
  ${({ theme }) => theme.grid.grid()}
  ${({ theme }) => theme.grid.gridGutterX(null, { nested: true })}
  ${({ theme }) => theme.grid.gridGutterY(30)}

  margin-top: ${rem(20)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: 0;
  }

  > li {
    ${({ theme }) => theme.grid.cell(4)}

    @media ${({ theme }) => theme.mq.mediumDown} {
      ${({ theme }) => theme.grid.cell(6)}
    }

    @media ${({ theme }) => theme.mq.xsmallDown} {
      ${({ theme }) => theme.grid.cell(12)}
    }
  }
`

const Grid = styled.ul`
  ${({ theme }) => theme.grid.grid()}
  ${({ theme }) => theme.grid.gridGutterX(null, { nested: true })}
  ${({ theme }) => theme.grid.gridGutterY(20)}

  > li {
    ${({ theme }) => theme.grid.cell(4)}

    @media ${({ theme }) => theme.mq.xlargeDown} {
      ${({ theme }) => theme.grid.cell(6)}
    }

    @media ${({ theme }) => theme.mq.smallDown} {
      ${({ theme }) => theme.grid.cell(12)}
    }

    > * {
      width: 80%;

      @media ${({ theme }) => theme.mq.smallDown} {
        width: 90%;
      }
    }
  }
`

const Upcoming = styled.section`
  ${({ theme }) => theme.grid.container()}

  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${rem(20)};
    padding-bottom: ${rem(20)};
  }

  > ${Label3} {
    margin-bottom: 2em;
    padding-bottom: 1.5em;
    color: ${({ theme }) => theme.colors.metallic};
    border-bottom: 1px solid ${({ theme }) => theme.colors.cream};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-bottom: 1em;
      padding-bottom: 0.5em;
    }
  }
`

const EventsPage = ({ data: { config, events } }) => {
  const heroItemsMax = 3
  let heroItems = null
  let gridItems = null

  if (events && events.nodes.length >= heroItemsMax) {
    heroItems = events.nodes.slice(0, heroItemsMax)
    gridItems = events.nodes.slice(heroItemsMax)
  } else {
    heroItems = events.nodes
  }

  return (
    <Layout>
      <Hero>
        <div>
          <h1>
            <Icon
              iconSvg={<SvgCalendar />}
              className="-icon"
              aria-hidden="true"
              iconColor="creamLight"
            />

            <Label2
              dangerouslySetInnerHTML={{
                __html: config.pages.events.title,
              }}
            />
          </h1>

          {heroItems && heroItems.length > 0 && (
            <Highlights>
              {heroItems.map((item, i) => (
                <li key={i}>
                  <EventCard data={item} major={i === 0} />
                </li>
              ))}
            </Highlights>
          )}
        </div>
      </Hero>

      {gridItems && gridItems.length > 0 && (
        <Upcoming>
          {/* <Label3 as="h2">Upcoming events</Label3> */}

          <Grid>
            {gridItems.map((item, i) => (
              <li key={i}>
                <EventCardTiny data={item} />
              </li>
            ))}
          </Grid>
        </Upcoming>
      )}
    </Layout>
  )
}

export default EventsPage

export const Head = ({ data: { config } }) => {
  return <Meta data={{ title: config.pages.events.title }} />
}

export const pageQuery = graphql`
  query {
    config: configJson {
      pages {
        events {
          title
        }
      }
    }

    events: allEventsJson(sort: { occurs_at: ASC }) {
      nodes {
        ...EventsCardFragment
      }
    }
  }
`
