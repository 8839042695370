import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem, hideVisually } from "polished"
import { Link, ImagesCarousel } from "@outdoormap/gatsby-plugin-brand-sites"

import SitesCarousel from "../sites-carousel"
import PostsCarousel from "../posts-carousel"
import Map from "../map"
import Styled from "../styled"
import Infobox from "../infobox"
import { Heading1 } from "../styled/heading"
import { Label3 } from "../styled/label"
import { ParagraphLarge } from "../styled/paragraph"
import { anchorStyles } from "../styled/anchor"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

const Article = styled.article`
  margin-bottom: ${rem(40)};
`

const Header = styled.header`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  margin-bottom: ${rem(50)};
  padding-top: ${rem(20)};
  text-align: center;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: ${rem(20)};
  }
`

const Date = styled.time`
  margin-bottom: 0.875em;
  display: block;
  font-size: 1.125em;
  color: ${({ theme }) => theme.colors.metallic};
`

const Title = styled(Heading1)`
  font-weight: bold;
`

const Tagline = styled(ParagraphLarge)`
  margin-top: 0.75em;
`

const Images = styled.div`
  ${({ theme }) => theme.grid.container(`narrow`)}

  > div {
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    border-radius: ${rem(8)};
  }
`

const Content = styled(Styled)``

const Sites = styled.footer`
  ${({ theme }) => theme.grid.container(`narrow`)}

  > div {
    margin-top: ${rem(40)};
    padding-top: ${rem(40)};
    border-top: 1px solid ${({ theme }) => theme.colors.cream};
  }

  ${Label3} {
    margin-bottom: ${rem(20)};
  }
`

const MapWrap = styled.section`
  height: ${rem(420)};
  overflow: hidden;
  border-radius: ${rem(5)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    height: ${rem(240)};
  }

  > h2 {
    ${hideVisually()}
  }
`

const InfoboxWrap = styled.div`
  ${({ theme }) => theme.grid.container(`xnarrow`)}
  margin-top: ${rem(30)};

  &:empty {
    display: none;
  }

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-top: ${rem(20)};
  }
`

const Columns = styled.div`
  ${({ theme }) => theme.grid.container(`narrow`)}
  ${({ theme }) => theme.grid.grid()}
  ${({ theme }) => theme.grid.gridGutterX()}

  margin-top: ${rem(50)};
  margin-bottom: ${rem(50)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: 0;
  }
`

const ColumnMain = styled.div`
  ${({ theme, $withMap }) => theme.grid.cell($withMap ? 8 : 12)}

  padding-right: 5%;

  @media ${({ theme }) => theme.mq.mediumDown} {
    ${({ theme }) => theme.grid.cell(12)}
  }
`

const ColumnAside = styled.aside`
  ${({ theme }) => theme.grid.cell(4)}

  @media ${({ theme }) => theme.mq.mediumDown} {
    ${({ theme }) => theme.grid.cell(12)}

    margin-top: ${rem(30)};
  }
`

const Other = styled.section`
  background-color: ${({ theme }) => theme.colors.cream};

  > div {
    ${({ theme }) => theme.grid.container()}

    padding-top: ${rem(50)};
    padding-bottom: ${rem(50)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: ${rem(20)};
      padding-top: ${rem(20)};
      padding-bottom: ${rem(20)};
    }
  }

  ${Label3} {
    margin-bottom: ${rem(20)};
    color: ${({ theme }) => theme.colors.metallic};
  }
`

const OtherCta = styled.footer`
  margin-top: ${rem(30)};

  a {
    ${anchorStyles}

    &:hover {
      svg {
        transform: translateX(0.3em);
      }
    }

    span {
      margin-right: 0.4em;
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      vertical-align: middle;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`

const Post = ({ data, other, otherHeading, otherCta, otherCtaUrl }) => {
  const anySites = data.sites && !!data.sites.length
  const mapQuery = anySites
    ? data.sites.map((s) => `site=${s.id}`).join(`&`)
    : null

  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          map
          mentionedInThisPost
        }
      }
    }
  `)

  if (!data) return null

  return (
    <React.Fragment>
      <Article>
        <Header>
          {data.date && (
            <Date
              datetime={data.date}
              dangerouslySetInnerHTML={{ __html: data.date }}
            />
          )}

          {data.title && (
            <Title dangerouslySetInnerHTML={{ __html: data.title }} />
          )}

          {data.tagline && (
            <Tagline dangerouslySetInnerHTML={{ __html: data.tagline }} />
          )}
        </Header>

        {!!data.images?.length && (
          <Images>
            <div>
              <ImagesCarousel data={data.images} title={data.title} />
            </div>
          </Images>
        )}

        <Columns>
          {!!data.body && (
            <ColumnMain $withMap={!!mapQuery}>
              <Content dangerouslySetInnerHTML={{ __html: data.body }} />
            </ColumnMain>
          )}

          {mapQuery && (
            <ColumnAside>
              <MapWrap>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: translations.global.map,
                  }}
                />

                <Map
                  query={mapQuery}
                  menu="fullscreen"
                  scrollZoom="false"
                  styleControl="fullscreen"
                  zoomControl="fullscreen"
                  measureControl="fullscreen"
                  locationControl="fullscreen"
                  printControl="fullscreen"
                />
              </MapWrap>

              <InfoboxWrap>
                <Infobox data={data} />
              </InfoboxWrap>
            </ColumnAside>
          )}
        </Columns>

        {anySites && (
          <Sites>
            <div>
              <Label3
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: `${translations.global.mentionedInThisPost}…`,
                }}
              />

              <SitesCarousel visibleSlides={2} data={data.sites} />
            </div>
          </Sites>
        )}
      </Article>

      {other && !!other.nodes.length && (
        <Other>
          <div>
            {otherHeading && (
              <Label3
                as="h2"
                dangerouslySetInnerHTML={{ __html: otherHeading }}
              />
            )}

            <PostsCarousel visibleSlides={3} data={other.nodes} />

            {otherCta && otherCtaUrl && (
              <OtherCta>
                <Link to={otherCtaUrl}>
                  <span dangerouslySetInnerHTML={{ __html: otherCta }} />

                  <SvgArrowRight aria-hidden="true" />
                </Link>
              </OtherCta>
            )}
          </div>
        </Other>
      )}
    </React.Fragment>
  )
}

export default Post
