import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { shuffle } from "lodash"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import SitesCarousel from "../sites-carousel"
import TypeIconSvg from "../type-icon-svg"
import Icon from "../icon"
import { Heading2 } from "../styled/heading"
import { Paragraph } from "../styled/paragraph"
import { anchorStyles } from "../styled/anchor"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

const Header = styled.header`
  display: grid;
  grid-template-areas:
    "a b"
    "a c";
  grid-template-columns: 1fr 13fr;
  grid-template-rows: 1fr 1fr;

  margin-bottom: ${rem(30)};
  padding-top: ${rem(30)};
  padding-right: ${rem(50)};
  position: relative;
  align-items: center;

  @media ${({ theme }) => theme.mq.mediumDown} {
    grid-template-areas:
      "a"
      "b"
      "c";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    max-height: ${rem(220)};
    margin-bottom: 0;
    align-items: unset;
    padding-top: ${rem(20)};
  }

  h2 {
    font-weight: bold;
  }

  > .-icon {
    grid-area: a;
    position: relative;
    margin-right: ${rem(10)};
    width: 4em;
    height: 4em;
    transition: transform 0.3s ${({ theme }) => theme.easings.default};

    svg {
      padding: 5%;
      border-radius: 10px;
    }

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-bottom: ${rem(10)};
    }
  }
`

const Cta = styled.footer`
  margin-top: ${rem(30)};

  a {
    color: ${({ theme }) => theme.colors.blue};
    ${anchorStyles}

    &:hover {
      svg {
        transform: translateX(0.3em);
      }
    }

    span {
      margin-right: 0.4em;
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      vertical-align: middle;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}

  width: 100%;
  padding-bottom: ${rem(50)};

  @media ${({ theme }) => theme.mq.xlargeDown} {
    padding-bottom: ${rem(20)};
  }
`

const IndexSites = ({ type, sites }) => {
  const anySites = !!(sites && sites.length)

  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          seeAll
        }
      }
    }
  `)

  const [shuffledSites, setShuffledSites] = useState(sites || [])

  useEffect(() => {
    if (anySites && type.name != `stages`) {
      setShuffledSites(shuffle(sites))
    }
  }, [])

  if (!anySites) return null

  return (
    <Container>
      <Header>
        <Icon
          iconSvg={<TypeIconSvg name={type.name} />}
          iconColor="blue"
          bgColor="yellow"
          className="-icon"
          aria-hidden="true"
        />

        {type.tagline && (
          <Heading2 dangerouslySetInnerHTML={{ __html: type.tagline }} />
        )}

        {type.description && (
          <Paragraph dangerouslySetInnerHTML={{ __html: type.description }} />
        )}
      </Header>

      <SitesCarousel
        data={shuffledSites}
        type={type.name}
        visibleSlides={3}
        hideTypeIcons={true}
      />

      <Cta>
        <Link to={`/${type.slug}`}>
          <span
            dangerouslySetInnerHTML={{ __html: translations.global.seeAll }}
          />

          <SvgArrowRight aria-hidden="true" />
        </Link>
      </Cta>
    </Container>
  )
}

export default IndexSites
