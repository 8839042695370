import { rgba, rem } from "polished"

const infobox = {
  color: ({ theme }) => theme.colors.black,
  backgroundColor: ({ theme }) => rgba(theme.colors.cream, 0.2),
  borderColor: ({ theme }) => rgba(theme.colors.gold, 0.2),
  iconColor: ({ theme }) => theme.colors.brown,
  br: rem(10),
}

export default infobox
