import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Map from "../components/map"
import TypeHero from "../components/type-hero"
import SitesGrid from "../components/sites-grid"
import { typesCategoriesPreselected } from "../config/type"

// const collectCategories = (data) => {
//   const categories = {}
//   data.nodes.forEach(
//     (n) => n.mainCategory && (categories[n.mainCategory.id] = n.mainCategory)
//   )
//   return Object.values(categories)
// }

const TypeTemplate = ({ data: { config, sites } }) => {
  const type = config.types[0]
  // const categories = useMemo(() => collectCategories(sites), [sites])
  const categories = typesCategoriesPreselected[type.name]

  const mapQuery = [
    `guide=${process.env.GATSBY_NATURKARTAN_GUIDE_ID}`,
    // ...categories.map((c) => `category=${c.id}`),
    ...categories.map((c) => `category=${c}`),
  ].join(`&`)

  return (
    <Layout>
      <TypeHero type={type}>
        <Map
          query={mapQuery}
          menu="fullscreen"
          scrollZoom="false"
          styleControl="fullscreen"
          zoomControl="true"
          measureControl="fullscreen"
          locationControl="fullscreen"
          printControl="fullscreen"
          legendControl="true"
          legend="true"
        />
      </TypeHero>

      <SitesGridWrap>
        <SitesGrid data={sites.nodes} type={type} />
      </SitesGridWrap>
    </Layout>
  )
}

export default TypeTemplate

export const Head = ({ data: { config } }) => {
  const type = config.types[0]

  return <Meta data={{ title: type.title, description: type.tagline }} />
}

export const pageQuery = graphql`
  query ($type: String!, $categories: [String]!) {
    config: configJson {
      types(name: $type) {
        name
        slug
        color
        title
        tagline
        description
        metaDescription: meta_description
      }
    }

    sites: allSitesJson(
      # filter: { categories: { elemMatch: { nk_id: { in: $categories } } } }
      filter: { main_category: { nk_id: { in: $categories } } }
      sort: { name: ASC }
    ) {
      nodes {
        ...SitesCardFragment

        categories {
          id: nk_id
        }
      }
    }
  }
`

const SitesGridWrap = styled.div`
  margin-top: ${rem(40)};
  margin-bottom: ${rem(40)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(30)};
    margin-bottom: ${rem(30)};
  }
`
