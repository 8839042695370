import { ReactComponent as SvgBiking } from "../../assets/images/icons/biking.svg"
import { ReactComponent as SvgPaddling } from "../../assets/images/icons/paddling.svg"
import { ReactComponent as SvgHiking } from "../../assets/images/icons/hiking.svg"
// import { ReactComponent as SvgMountainbike } from "../../assets/images/icons/mountainbiking.svg"
import { ReactComponent as SvgOutdoors } from "../../assets/images/icons/outdoors.svg"
// import { ReactComponent as SvgHub } from "../../assets/images/icons/hub.svg"

const icons = {
  // biking: SvgBiking,
  // mountainbiking: SvgMountainbike,
  mountainbiking: SvgBiking,
  hiking: SvgHiking,
  paddling: SvgPaddling,
  outdoors: SvgOutdoors,
  // hub: SvgHub,
}

const TypeIconSvg = ({ name, ...props }) => {
  if (!name) return null

  return icons[name](props)
}

export default TypeIconSvg
