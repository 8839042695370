import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import Icon from "../icon"
import TypeIconSvg from "../type-icon-svg"
import { Heading4 } from "../styled/heading"
import { ParagraphSmall } from "../styled/paragraph"

const Image = styled.figure`
  width: 100%;
  height: ${rem(350)};
  margin-bottom: 1em;
  position: relative;
  overflow: hidden;
  border-radius: ${rem(10)} ${rem(10)} 0 0;
  background-color: ${({ theme }) => theme.colors.gold};
  transition: opacity 0.3s ${({ theme }) => theme.easings.default};

  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  > .-icon {
    position: absolute;
    z-index: 2;
    top: 0.5em;
    right: 0.5em;
    transform: scale(0.8);
    cursor: help;

    svg {
      border-radius: ${rem(10)};
      padding: 10%;
    }
  }
`

const Location = styled(ParagraphSmall)`
  margin-top: 0.3em;
`
const TextContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  min-height: ${rem(80)};
  padding: ${rem(5)} ${rem(20)} ${rem(15)} ${rem(20)};
  border-radius: 0 0 ${rem(10)} ${rem(10)};
`

const Container = styled.article`
  width: 100%;
  background-color: white;
  border-radius: ${rem(10)};

  a {
    h3 {
      font-weight: bold;
    }

    &:hover {
      ${Image} {
        opacity: 0.8;
      }

      ${Heading4} {
        color: ${({ theme }) => theme.colors.blue};
      }
    }

    &:active {
      ${Image} {
        transition: none;
        opacity: 0.6;
      }
    }
  }
`

const SiteCard = ({
  data: { theType, theTypeData, name, path, image, location, excerpt },
  hideTypeIcon,
  ...props
}) => {
  return (
    <Container {...props}>
      <Link to={path}>
        <Image>
          {image && <GatsbyImage image={image} alt={name} />}

          {!hideTypeIcon && (
            <Icon
              iconSvg={<TypeIconSvg name={theType} />}
              className="-icon"
              aria-hidden="true"
              title={theTypeData.title}
              iconColor="blue"
              bgColor="yellow"
            />
          )}
        </Image>

        <TextContainer>
          {name && (
            <Heading4 as="h3" dangerouslySetInnerHTML={{ __html: name }} />
          )}

          {excerpt && (
            <Location
              dangerouslySetInnerHTML={{
                __html: excerpt,
              }}
            />
          )}
        </TextContainer>
      </Link>
    </Container>
  )
}

export default SiteCard
