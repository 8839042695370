import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import PostCardHighlight from "../post-card-highlight"
import { Label2 } from "../styled/label"
import { anchorStyles } from "../styled/anchor"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

const Container = styled.section`
  padding: ${rem(40)} 0;
  background-color: ${({ theme }) => theme.colors.darkGrey};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${rem(20)};
    padding-bottom: ${rem(20)};
  }

  > div {
    ${({ theme }) => theme.grid.container()}
  }
`

const Heading = styled.h2`
  ${({ theme }) => theme.fonts.set(`primary`, `normal`)}

  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.lightBlue};
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  text-transform: uppercase;
`

const Highlights = styled.ul`
  margin-top: ${rem(20)};
  display: grid;
  grid-gap: ${rem(15)};
  grid-template-columns: 2fr 1fr;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(20)};
    grid-template-columns: 1fr 1fr;
  }

  @media ${({ theme }) => theme.mq.xsmallDown} {
    grid-template-columns: 1fr;
  }

  > li:first-child {
    grid-row: 1 / 3;

    @media ${({ theme }) => theme.mq.mediumDown} and ${({ theme }) =>
        theme.mq.xsmallUp} {
      grid-column: 1 / 3;
    }

    > * {
      @media ${({ theme }) => theme.mq.mediumUp} {
        height: 100%;
      }
    }
  }
`

const More = styled.div`
  margin-top: 1.875em;

  a {
    color: ${({ theme }) => theme.colors.lightBlue};

    &:hover {
      svg {
        transform: translateX(0.3em);
      }

      color: ${({ theme }) => theme.colors.yellow};
    }

    span {
      ${anchorStyles}

      margin-right: 0.25em;

      &:hover {
        color: ${({ theme }) => theme.colors.yellow};
      }
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      margin-top: -0.1em;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`

const PostsHighlights = ({
  data,
  heading,
  headingLevel = 2,
  moreText,
  moreLink,
}) => {
  if (!data || !data.length) return null

  return (
    <Container>
      <div>
        <Heading as={`h${headingLevel}`}>
          <Label2 dangerouslySetInnerHTML={{ __html: heading }} />
        </Heading>

        <Highlights>
          {data.map((item, i) => (
            <li key={i}>
              <PostCardHighlight data={item} major={i === 0} />
            </li>
          ))}
        </Highlights>

        {moreText && moreLink && (
          <More>
            <Link to={moreLink}>
              <span dangerouslySetInnerHTML={{ __html: moreText }} />

              <SvgArrowRight aria-hidden="true" />
            </Link>
          </More>
        )}
      </div>
    </Container>
  )
}

export default PostsHighlights
