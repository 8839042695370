let colors = {
  white: `#fff`,
  black: `#29292b`,
  darkerBlack: `#1e1e1e`,
  metallic: `#43484c`,
  cream: `#efece8`,
  creamLight: `#f4f2ef`,
  gold: `#896c4c`,
  yellow: `#ffcd00`,
  brown: `#bb8b6f`,
  blue: `#005096`,
  lightBlue: `#dce6f0`,
  green: `#41885c`,
  red: `#884141`,
  naturkartanMapBg: `#f3f3ef`,
  darkGrey: `#465a64`,
}

colors = {
  ...colors,
  // by function:
  imageBackground: colors.brown,
}

export default colors
