import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import TypeCtas from "../type-ctas"
import { Heading1 } from "../styled/heading"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

const Container = styled.section`
  @media ${({ theme }) => theme.mq.xsmallUp} {
    ${({ theme }) => theme.grid.container()}
  }

  > div {
    position: relative;
    overflow: hidden;
    border-radius: ${rem(8)};
    background-color: ${({ theme }) => theme.colors.brown};

    @media ${({ theme }) => theme.mq.mediumDown} {
      padding-bottom: ${rem(10)};
    }

    @media ${({ theme }) => theme.mq.xsmallDown} {
      border-radius: 0;
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4)
      );
    }
  }
`

const Media = styled.div`
  > video,
  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
  }

  > video {
    height: 100%;
    width: 130vh;
    min-width: 100%;
    min-height: 56vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Text = styled.div`
  width: 80%;
  margin: ${rem(80)} auto 0;
  position: relative;
  z-index: 3;
  max-width: ${rem(750)};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.mq.xsmallDown} {
    width: 95%;
  }

  h1 {
    margin-bottom: 0.4em;
    padding-bottom: ${rem(30)};
    font-weight: bold;
    font-size: ${rem(48)};

    @media ${({ theme }) => theme.mq.largeDown} {
      font-size: ${rem(42)};
    }

    @media ${({ theme }) => theme.mq.mediumDown} {
      font-size: ${rem(38)};
    }

    @media ${({ theme }) => theme.mq.xsmallDown} {
      font-size: ${rem(28)};
    }
  }

  a {
    ${({ theme }) => theme.fonts.set(`primary`, `semibold`)};

    color: ${({ theme }) => theme.colors.yellow};
    font-weight: 600;
    font-size: ${rem(23)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      font-size: ${rem(20)};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.white};

      svg {
        transform: translateX(0.3em);
      }
    }

    &:active {
      opacity: 0.6;
    }

    span {
      margin-right: 0.25em;
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      margin-top: -0.1em;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`

const TextCtas = styled.div`
  padding-top: ${rem(10)};
  padding-bottom: ${rem(30)};
`

const MediaBlock = ({ data, headingLevel = 2, ...props }) => {
  if (!data) return null

  return (
    <Container {...props}>
      <div>
        <Media>
          {data.video && (
            <video
              src={data.video}
              preload="auto"
              playsInline
              autoPlay
              loop
              muted
            />
          )}

          {data.image && (
            <GatsbyImage
              image={data.image.childImageSharp.gatsbyImageData}
              alt={data.title}
            />
          )}
        </Media>

        <Text>
          {data.title && (
            <Heading1
              as={`h${headingLevel}`}
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
          )}

          {data.cta && data.cta.url && data.cta.title && (
            <TextCtas>
              <Link to={data.cta.url}>
                <span dangerouslySetInnerHTML={{ __html: data.cta.title }} />

                <SvgArrowRight aria-hidden="true" />
              </Link>
            </TextCtas>
          )}
        </Text>

        <TypeCtas />
      </div>
    </Container>
  )
}

export default MediaBlock
