import React from "react"
import { graphql } from "gatsby"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Post from "../components/post"

const ArticlesPostTemplate = ({
  data: { translations, config, article, latest },
}) => {
  return (
    <Layout>
      <Post
        data={article}
        other={latest}
        otherHeading={translations.global.latestArticles}
        otherCta={translations.global.seeAll}
        otherCtaUrl={`/${config.pages.articles.slug}`}
      />
    </Layout>
  )
}

export default ArticlesPostTemplate

export const Head = ({ data: { article } }) => {
  return (
    <Meta
      data={{
        title: article.title,
        socialImage: article.images[0]?.images.fallback.src,
      }}
    />
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    translations: translationsJson {
      global {
        latestArticles
        seeAll
      }
    }

    config: configJson {
      pages {
        articles {
          slug
        }
      }
    }

    article: articlesJson(id: { eq: $id }) {
      title
      body
      links {
        id
        url
        text
      }
      files {
        id
        url
        text
      }
      images {
        ...NKimageFragment
      }
      sites {
        ...SitesCardFragment
      }
    }

    latest: allArticlesJson(
      filter: { id: { ne: $id } }
      sort: { publish_at: DESC }
      limit: 3
    ) {
      nodes {
        ...ArticlesCardFragment
      }
    }
  }
`
