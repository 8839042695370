import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem, darken } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import TypeIconSvg from "../type-icon-svg"
import { heading3Styles } from "../styled/heading"

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}

  align-self: flex-end;
  position: relative;
  z-index: 3;

  ul {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    flex-wrap: wrap;
    margin: ${rem(50)} ${rem(40)};

    @media ${({ theme }) => theme.mq.xlargeDown} {
      grid-template-columns: 1fr 1fr;
      margin: ${rem(5)};
    }

    @media ${({ theme }) => theme.mq.mediumDown} {
      grid-template-columns: 1fr;
      margin: ${rem(5)};
    }
  }

  li {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

    min-width: 33.333%;
    flex: 1;
    display: flex;
    padding: ${rem(7)};

    @media ${({ theme }) => theme.mq.largeDown} {
      min-width: 50%;
    }

    @media ${({ theme }) => theme.mq.xsmallDown} {
      min-width: 100%;
      padding: ${rem(3)};
    }
  }
`

const LinkStyled = styled(Link)`
  ${heading3Styles}

  width: 100%;
  height: 100%;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${rem(20)};
  color: ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  border-radius: ${rem(10)};
  transition: background-color 0.3s ${({ theme }) => theme.easings.default};

  @media ${({ theme }) => theme.mq.mediumDown} {
    min-width: 50%;
    padding: 0.6em;
  }

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.colors.lightBlue)};

    > svg {
      transform: scale(1.15);
    }
  }

  &:active {
    transition: none;
    background-color: ${({ theme, $color }) =>
      darken(0.2, theme.colors[$color || `metallic`])};
  }

  svg {
    width: 1.4em;
    height: 1.4em;
    transition: transform 0.3s ${({ theme }) => theme.easings.default};
    margin-left: -1.4em;
    margin-right: 0.3em;
  }

  > span {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}

    white-space: nowrap;
  }
`

const TypeCtas = () => {
  const {
    config: { types },
  } = useStaticQuery(graphql`
    query {
      config: configJson {
        types {
          name
          slug
          color
          title
        }
      }
    }
  `)
  return (
    <Container>
      <ul>
        {types.map(({ name, slug, color, title }, i) => (
          <li key={i}>
            <LinkStyled to={`/${slug}`} $color={color}>
              <TypeIconSvg name={name} />

              <span dangerouslySetInnerHTML={{ __html: title }} />
            </LinkStyled>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default TypeCtas
